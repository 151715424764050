import constants from '@/helpers/constants'
import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import VueResource from 'vue-resource'

Vue.use(Router)
Vue.use(VueResource)

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: 'home',
			component: Home,
			meta: {
				title: 'TracTrac - Leaders in live tracking | Sailing | Orienteering | Cycling | Skiing | Triathlon ',
				metaTags: [
					{ name: 'description', content: 'TracTrac leaders in live tracking of sports' },
					{ name: 'twitter:description', content: 'TracTrac leaders in live tracking of sports' },
					{ property: 'og:description', content: 'TracTrac leaders in live tracking of sports' },
					{ name: 'twitter:title', content: 'TracTrac - Leaders in live tracking' },
					{ property: 'og:title', content: 'TracTrac - Leaders in live tracking' },
					{ name: 'twitter:image', content: `${constants.WEB_URL}/tractrac.png` },
					{ property: 'og:image', content: `${constants.WEB_URL}/tractrac.png` },
					{ name: 'twitter:site', content: '@tractraclive' },
					{ name: 'twitter:creator', content: '@tractraclive' },
					{ property: 'og:type', content: 'website' },
					{ property: 'og:url', content: `${constants.ROOT_URL}` },
					{ property: 'og:site_name', content: 'TracTrac - Leaders in live tracking' }
				]
			}
		},
		{
			path: '/events-list',
			name: 'Events',
			component: () => import(/* webpackChunkName: "events" */ './views/EventsList.vue'),
			meta: {
				title: 'Events - TracTrac',
				metaTags: [
					{ name: 'description', content: 'All events using TracTrac.' },
					{ name: 'twitter:description', content: 'All events using TracTrac.' },
					{ property: 'og:description', content: 'All events using TracTrac.' },
					{ name: 'twitter:title', content: 'Events - TracTrac' },
					{ property: 'og:title', content: 'Events - TracTrac' },
					{ name: 'twitter:image', content: `${constants.WEB_URL}/tractrac.png` },
					{ property: 'og:image', content: `${constants.WEB_URL}/tractrac.png` },
					{ name: 'twitter:site', content: '@tractraclive' },
					{ name: 'twitter:creator', content: '@tractraclive' },
					{ property: 'og:type', content: 'website' },
					{ property: 'og:url', content: `${constants.ROOT_URL}/events-list` },
					{ property: 'og:site_name', content: 'TracTrac - Leaders in live tracking' }
				]
			}
		},
		{
			path: '/events-list/:override',
			name: 'Events',
			component: () => import(/* webpackChunkName: "events" */ './views/EventsList.vue'),
			meta: {
				title: 'Events - TracTrac'
			}
		},
		{
			path: '/event-page/:db/:id',
			name: 'Event page',
			component: () => import(/* webpackChunkName: "events" */ './views/EventPage.vue'),
			meta: {
				title: 'Event - TracTrac'
			}
		},
		{
			path: '/event-page/:db/:id/:embedded',
			name: 'Event page embedded',
			component: () => import(/* webpackChunkName: "events" */ './views/EventPage.vue'),
			meta: {
				title: 'Event - TracTrac'
			}
		},
		{
			path: '/event-page/:db/:id/:embedded/:override',
			name: 'Event page embedded',
			component: () => import(/* webpackChunkName: "events" */ './views/EventPage.vue'),
			meta: {
				title: 'Event - TracTrac'
			}
		},
		{
			path: '/event-page/:db/:id/:embedded/:date',
			name: 'Event page embedded dated',
			component: () => import(/* webpackChunkName: "events" */ './views/EventPage.vue'),
			meta: {
				title: 'Event - TracTrac'
			}
		},
		{
			path: '/clubs-list',
			name: 'Clubs',
			component: () => import(/* webpackChunkName: "clubs" */ './views/ClubsList.vue'),
			meta: {
				title: 'Clubs - TracTrac',
				metaTags: [
					{ name: 'description', content: 'All clubs using TracTrac.' },
					{ name: 'twitter:description', content: 'All clubs using TracTrac.' },
					{ property: 'og:description', content: 'All clubs using TracTrac.' },
					{ name: 'twitter:title', content: 'Clubs - TracTrac' },
					{ property: 'og:title', content: 'Clubs - TracTrac' },
					{ name: 'twitter:image', content: `${constants.WEB_URL}/tractrac.png` },
					{ property: 'og:image', content: `${constants.WEB_URL}/tractrac.png` },
					{ name: 'twitter:site', content: '@tractraclive' },
					{ name: 'twitter:creator', content: '@tractraclive' },
					{ property: 'og:type', content: 'website' },
					{ property: 'og:url', content: `${constants.ROOT_URL}/clubs-list` },
					{ property: 'og:site_name', content: 'TracTrac - Leaders in live tracking' }
				]
			}
		},
		{
			path: '/club-page/:id/:name',
			name: 'Club page',
			component: () => import(/* webpackChunkName: "clubs" */ './views/ClubPage.vue'),
			meta: {
				title: 'Club - TracTrac'
			}
		},
		{
			path: '/get-event',
			name: 'Get Event Sailing',
			component: () => import(/* webpackChunkName: "getEvent" */ './views/GetEventSailing.vue'),
			meta: {
				title: 'Get Event Sailing - TracTrac',
				metaTags: [
					{ name: 'description', content: 'These are the benefits you get using TracTrac for your Sailing event.' },
					{ name: 'twitter:description', content: 'These are the benefits you get using TracTrac for your Sailing event.' },
					{ property: 'og:description', content: 'These are the benefits you get using TracTrac for your Sailing event.' },
					{ name: 'twitter:title', content: 'Get Event Sailing - TracTrac' },
					{ property: 'og:title', content: 'Get Event Sailing - TracTrac' },
					{ name: 'twitter:image', content: `${constants.WEB_URL}/tractrac.png` },
					{ property: 'og:image', content: `${constants.WEB_URL}//tractrac.png` },
					{ name: 'twitter:site', content: '@tractraclive' },
					{ name: 'twitter:creator', content: '@tractraclive' },
					{ property: 'og:type', content: 'website' },
					{ property: 'og:url', content: `${constants.ROOT_URL}/get-event` },
					{ property: 'og:site_name', content: 'TracTrac - Leaders in live tracking' }
				]
			}
		},
		{
			path: '/getquote',
			name: 'Get Quote',
			component: () => import(/* webpackChunkName: "getEvent" */ './views/GetEventSailing.vue'),
			alias: '/get-event',
			meta: {
				title: 'Get quote - TracTrac',
				metaTags: [
					{ name: 'description', content: 'These are the benefits you get using TracTrac for your event.' },
					{ name: 'twitter:description', content: 'These are the benefits you get using TracTrac for your event.' },
					{ property: 'og:description', content: 'These are the benefits you get using TracTrac for your event.' },
					{ name: 'twitter:title', content: 'Get quote - TracTrac' },
					{ property: 'og:title', content: 'Get quote - TracTrac' },
					{ name: 'twitter:image', content: `${constants.WEB_URL}/tractrac.png` },
					{ property: 'og:image', content: `${constants.WEB_URL}/tractrac.png` },
					{ name: 'twitter:site', content: '@tractraclive' },
					{ name: 'twitter:creator', content: '@tractraclive' },
					{ property: 'og:type', content: 'website' },
					{ property: 'og:url', content: `${constants.ROOT_URL}/getquotet` },
					{ property: 'og:site_name', content: 'TracTrac - Leaders in live tracking' }
				]
			}
		},
		{
			path: '/get-event-navigation-sports',
			name: 'Get Event Navigation Sports',
			component: () => import(/* webpackChunkName: "getEvent" */ './views/GetEventNavigation.vue'),
			meta: {
				title: 'Get Event Navigation Sports - TracTrac',
				metaTags: [
					{ name: 'description', content: 'These are the benefits you get using TracTrac for your Navigation sport event.' },
					{ name: 'twitter:description', content: 'These are the benefits you get using TracTrac for your Navigation sport event.' },
					{ property: 'og:description', content: 'These are the benefits you get using TracTrac for your Navigation sport event.' },
					{ name: 'twitter:title', content: 'Get Event Navigation Sports - TracTrac' },
					{ property: 'og:title', content: 'Get Event Navigation Sports - TracTrac' },
					{ name: 'twitter:image', content: `${constants.WEB_URL}/tractrac.png` },
					{ property: 'og:image', content: `${constants.WEB_URL}/tractrac.png` },
					{ name: 'twitter:site', content: '@tractraclive' },
					{ name: 'twitter:creator', content: '@tractraclive' },
					{ property: 'og:type', content: 'website' },
					{ property: 'og:url', content: `${constants.ROOT_URL}/get-event-route-sports` },
					{ property: 'og:site_name', content: 'TracTrac - Leaders in live tracking' }
				]
			}
		},
		{
			path: '/get-event-route-sports',
			name: 'Get Event Route Sports',
			component: () => import(/* webpackChunkName: "getEvent" */ './views/GetEventRoute.vue'),
			meta: {
				title: 'Get Event Route Sports - TracTrac',
				metaTags: [
					{ name: 'description', content: 'These are the benefits you get using TracTrac for your Route sport event.' },
					{ name: 'twitter:description', content: 'These are the benefits you get using TracTrac for your Route sport event.' },
					{ property: 'og:description', content: 'These are the benefits you get using TracTrac for your Route sport event.' },
					{ name: 'twitter:title', content: 'Get Event Route Sports - TracTrac' },
					{ property: 'og:title', content: 'Get Event Route Sports - TracTrac' },
					{ name: 'twitter:image', content: `${constants.WEB_URL}/tractrac.png` },
					{ property: 'og:image', content: `${constants.WEB_URL}/tractrac.png` },
					{ name: 'twitter:site', content: '@tractraclive' },
					{ name: 'twitter:creator', content: '@tractraclive' },
					{ property: 'og:type', content: 'website' },
					{ property: 'og:url', content: `${constants.ROOT_URL}/get-event-route-sports` },
					{ property: 'og:site_name', content: 'TracTrac - Leaders in live tracking' }
				]
			}
		},
		{
			path: '/get-custom-solutions',
			name: 'Get Custom Solutions',
			component: () => import(/* webpackChunkName: "getEvent" */ './views/GetEventCustom.vue'),
			meta: {
				title: 'Get Custom Solutions - TracTrac',
				metaTags: [
					{ name: 'description', content: 'These are the benefits you get using TracTrac for your event.' },
					{ name: 'twitter:description', content: 'These are the benefits you get using TracTrac for your event.' },
					{ property: 'og:description', content: 'These are the benefits you get using TracTrac for your event.' },
					{ name: 'twitter:title', content: 'Get Custom Solutions - TracTrac' },
					{ property: 'og:title', content: 'Get Custom Solutions - TracTrac' },
					{ name: 'twitter:image', content: `${constants.WEB_URL}/tractrac.png` },
					{ property: 'og:image', content: `${constants.WEB_URL}/tractrac.png` },
					{ name: 'twitter:site', content: '@tractraclive' },
					{ name: 'twitter:creator', content: '@tractraclive' },
					{ property: 'og:type', content: 'website' },
					{ property: 'og:url', content: `${constants.ROOT_URL}/get-custom-solutions` },
					{ property: 'og:site_name', content: 'TracTrac - Leaders in live tracking' }
				]
			}
		},
		{
			path: '/get-club-orienteering',
			name: 'Get Club Orienteering',
			component: () => import(/* webpackChunkName: "getClub" */ './views/GetClubOrienteering.vue'),
			meta: {
				title: 'Get Club Orienteering - TracTrac',
				metaTags: [
					{ name: 'description', content: 'These are the benefits you get using TracTrac in your Orienteering Club.' },
					{ name: 'twitter:description', content: 'These are the benefits you get using TracTrac in your Orienteering Club.' },
					{ property: 'og:description', content: 'These are the benefits you get using TracTrac in your Orienteering Club.' },
					{ name: 'twitter:title', content: 'Get Club Orienteering - TracTrac' },
					{ property: 'og:title', content: 'Get Club Orienteering - TracTrac' },
					{ name: 'twitter:image', content: `${constants.WEB_URL}/tractrac.png` },
					{ property: 'og:image', content: `${constants.WEB_URL}/tractrac.png` },
					{ name: 'twitter:site', content: '@tractraclive' },
					{ name: 'twitter:creator', content: '@tractraclive' },
					{ property: 'og:type', content: 'website' },
					{ property: 'og:url', content: `${constants.ROOT_URL}/get-club-orienteering` },
					{ property: 'og:site_name', content: 'TracTrac - Leaders in live tracking' }
				]
			}
		},
		{
			path: '/get-club-sailing',
			name: 'Get Club Sailing',
			component: () => import(/* webpackChunkName: "getClub" */ './views/GetClubSailing.vue'),
			meta: {
				title: 'Get Club Sailing - TracTrac',
				metaTags: [
					{ name: 'description', content: 'These are the benefits you get using TracTrac in your Sailing Club.' },
					{ name: 'twitter:description', content: 'These are the benefits you get using TracTrac in your Sailing Club.' },
					{ property: 'og:description', content: 'These are the benefits you get using TracTrac in your Sailing Club.' },
					{ name: 'twitter:title', content: 'Get Club Sailing - TracTrac' },
					{ property: 'og:title', content: 'Get Club Sailing - TracTrac' },
					{ name: 'twitter:image', content: `${constants.WEB_URL}/tractrac.png` },
					{ property: 'og:image', content: `${constants.WEB_URL}/tractrac.png` },
					{ name: 'twitter:site', content: '@tractraclive' },
					{ name: 'twitter:creator', content: '@tractraclive' },
					{ property: 'og:type', content: 'website' },
					{ property: 'og:url', content: `${constants.ROOT_URL}/get-club-sailing` },
					{ property: 'og:site_name', content: 'TracTrac - Leaders in live tracking' }
				]
			}
		},
		{
			path: '/become-a-partner',
			name: 'Become a partner',
			component: () => import(/* webpackChunkName: "BecomePartner" */ './views/BecomePartner.vue'),
			meta: {
				title: 'Become a partner - TracTrac',
				metaTags: [
					{ name: 'description', content: 'TracTrac is teaming up with high quality partners around the world to offer the market’s best live tracking to event organisers and clubs.' },
					{ name: 'twitter:description', content: 'TracTrac is teaming up with high quality partners around the world to offer the market’s best live tracking to event organisers and clubs.' },
					{ property: 'og:description', content: 'TracTrac is teaming up with high quality partners around the world to offer the market’s best live tracking to event organisers and clubs.' },
					{ name: 'twitter:title', content: 'Become a partner - TracTrac' },
					{ property: 'og:title', content: 'Become a partner - TracTrac' },
					{ name: 'twitter:image', content: `${constants.WEB_URL}/tractrac.png` },
					{ property: 'og:image', content: `${constants.WEB_URL}/tractrac.png` },
					{ name: 'twitter:site', content: '@tractraclive' },
					{ name: 'twitter:creator', content: '@tractraclive' },
					{ property: 'og:type', content: 'website' },
					{ property: 'og:url', content: `${constants.ROOT_URL}/become-a-partner` },
					{ property: 'og:site_name', content: 'TracTrac - Leaders in live tracking' }
				]
			}
		},
		{
			path: '/about',
			name: 'About',
			component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
			meta: {
				title: 'About - TracTrac',
				metaTags: [
					{ name: 'description', content: 'We give visibility to the sport, engage the spectator and drive fan base and sponsor value by revealing and visualizing the key moments of hard-to-follow-sports in real time.' },
					{ name: 'twitter:description', content: 'We give visibility to the sport, engage the spectator and drive fan base and sponsor value by revealing and visualizing the key moments of hard-to-follow-sports in real time.' },
					{ property: 'og:description', content: 'We give visibility to the sport, engage the spectator and drive fan base and sponsor value by revealing and visualizing the key moments of hard-to-follow-sports in real time.' },
					{ name: 'twitter:title', content: 'About - TracTrac' },
					{ property: 'og:title', content: 'About - TracTrac' },
					{ name: 'twitter:image', content: `${constants.WEB_URL}/tractrac.png` },
					{ property: 'og:image', content: `${constants.WEB_URL}/tractrac.png` },
					{ name: 'twitter:site', content: '@tractraclive' },
					{ name: 'twitter:creator', content: '@tractraclive' },
					{ property: 'og:type', content: 'website' },
					{ property: 'og:url', content: `${constants.ROOT_URL}/about` },
					{ property: 'og:site_name', content: 'TracTrac - Leaders in live tracking' }
				]
			}
		},
		{
			path: '/newsletter',
			name: 'Newsletter',
			component: () => import(/* webpackChunkName: "about" */ './views/Newsletter.vue'),
			meta: {
				title: 'Newsletter - TracTrac',
				metaTags: [
					{ name: 'description', content: 'Subscribe to stay informed about upcoming events and news about tracking.' },
					{ name: 'twitter:description', content: 'Subscribe to stay informed about upcoming events and news about tracking.' },
					{ property: 'og:description', content: 'Subscribe to stay informed about upcoming events and news about tracking.' },
					{ name: 'twitter:title', content: 'Newsletter - TracTrac' },
					{ property: 'og:title', content: 'Newsletter - TracTrac' },
					{ name: 'twitter:image', content: `${constants.WEB_URL}/tractrac.png` },
					{ property: 'og:image', content: `${constants.WEB_URL}/tractrac.png` },
					{ name: 'twitter:site', content: '@tractraclive' },
					{ name: 'twitter:creator', content: '@tractraclive' },
					{ property: 'og:type', content: 'website' },
					{ property: 'og:url', content: `${constants.ROOT_URL}/newsletter` },
					{ property: 'og:site_name', content: 'TracTrac - Leaders in live tracking' }
				]
			}
		},
		{
			path: '/event-manager',
			name: 'Login',
			component: () => import(/* webpackChunkName: "about" */ './views/Login.vue'),
			meta: {
				title: 'Login - TracTrac'
			}
		},
		{
			path: '/test/web-viewer',
			name: 'Web Viewer General Testing',
			component: () => import(/* webpackChunkName: "about" */ './views/WebViewerTests.vue'),
			meta: {
				title: 'Web Viewer General Testing - TracTrac'
			}
		},
		{
			path: '/how-to-watch-tractrac',
			name: 'How to view',
			component: () => import(/* webpackChunkName: "about" */ './views/HowToWatchTracTrac.vue'),
			meta: {
				title: 'How to view - TracTrac'
			}
		},
		{
			path: '/check',
			name: 'check',
			component: () => import('./views/Check.vue'),
			meta: {
				title: 'Check your tracking device - TracTrac'
			}
		},
		{
			path: '/checkall',
			name: 'checkall',
			component: () => import('./views/CheckAll.vue'),
			meta: {
				title: 'Check your tracking devices - TracTrac'
			}
		},
		{
			path: '/checkall/:eventId',
			name: 'checkallevent',
			component: () => import('./views/CheckAllEvent.vue'),
			meta: {
				title: 'Check your tracking devices for your event - TracTrac'
			}
		},
		{
			path: '/privacy-policy',
			name: 'Privacy Policy',
			component: () => import('./views/PrivacyPolicy.vue'),
			meta: {
				title: 'Privacy Policy - TracTrac'
			}
		},
		{
			path: '/event-content-page/:db/:id',
			name: 'event-content-webview',
			component: () => import('./views/EventContentWebView.vue'),
			props: true
		},
		{
			path: '/club-content-page/:db/:id',
			name: 'club-content-webview',
			component: () => import('./views/ClubContentWebView.vue'),
			props: true
		},
		{
			path: '*',
			component: () => import(/* webpackChunkName: "fourofour" */ './views/PageNotFound.vue'),
			meta: {
				title: '404 - TracTrac'
			}
		}
	],
	scrollBehavior (to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	}
})
