<template>
	<div id="app">
		<Navigation v-if="shouldShowNavAndFooter" />
		<router-view/>
		<Footer v-if="shouldShowNavAndFooter" />
	</div>
</template>

<script>
// @ is an alias to /src
import Navigation from '@/components/Navigation.vue'
import Footer from '@/components/Footer.vue'

// TODO move all images to S3
export default {
	name: 'App',
	components: {
		Navigation,
		Footer
	},
	computed: {
		shouldShowNavAndFooter () {
			// Don't show navigation and footer for:
			// 1. Routes ending with 'check' or 'checkall'
			// 2. WebView routes
			// 3. Routes with null name
			return this.$route.name !== null &&
				!this.$route.name.endsWith('check') &&
				!this.$route.name.endsWith('checkall') &&
				!this.$route.name.includes('webview') &&
				!this.$route.path.includes('/webview/')
		}
	}

}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600|Raleway:500');

$grey: #878787;

html {
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	background: #f4f4f4;
}

body {
	margin: 0;
	background: #ffffff;
}

label.warning {
	color: rgba(255,0,0,1);
}

label.ok {
	color: rgba(0,255,0,1);
}

select.normal {
	font-family: 'Raleway', sans-serif;
	font-size: 16px;
	padding: 4px 4px;
	border-radius: 4px;
	box-shadow: 0px 4px 4px rgba(0,0,0,0.3);
	max-width: 80%;
}

input[type=submit].normal,
button.normal {
	font-family: 'Raleway', sans-serif;
	text-transform: uppercase;
	font-size: 16px;
	padding: 10px 24px;
	border-radius: 4px;
	background: #63b323;
	box-shadow: 0px 4px 4px rgba(0,0,0,0.3);
	border: none;
	color: white;
	cursor: pointer;
	text-shadow: 0px 0px 4px rgba(0,0,0,0.2);
}

input[type=submit].normal:hover,
button.normal:hover {
	box-shadow: 0px 2px 2px rgba(0,0,0, 0.5);
}

input[type=submit].normal:active,
button.normal:active {
	box-shadow: 0px 1px 2px rgba(0,0,0, 0.15);
}

button:disabled {
	cursor: not-allowed;
	box-shadow: none;
	opacity: 0.6;
}

.container {
	width: 1200px;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	box-sizing: border-box;
}

@media only screen and (max-width: 1199px) {
	.container {
		width: 100%;
		position: relative;
		left: 0;
		transform: translateX(0);
	}
}

.gradientOverlay {
	background: rgb(0,90,132);
	background: linear-gradient(90deg, rgba(0,90,132,1) 0%, rgba(0,159,194,1) 100%);
	width: 100%;
	height: 100%;
	opacity: 0.75;
	position: absolute;
}

.notransition {
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-o-transition: none !important;
	transition: none !important;
}

input[type=email],
input[type=text],
input[type=password],
textarea {
    display: block;
    height: 36px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.61803399;
    color: #555;
    background-color: white;
    background-image: none;
    border: 1px solid $grey;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
	box-sizing: border-box;
}

input[type=email]:focus,
input[type=text]:focus,
input[type=password]:focus,
textarea:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
}

:focus {
    outline: none !important;
}

img.adventure,
img.cycling,
img.equestrian,
img.kayak,
img.kite_surfing,
img.mountain_biking,
img.orienteering,
img.paddling,
img.rowing,
img.running,
img.sailing,
img.skiing_cross_country,
img.swimming,
img.triathlon,
img.default {
	border-radius: 50%;
	width: 18px;
	height: 18px;
}

img.sailing {
	background-color: #0091EA;
}

img.adventure {
	background-color: #304FFE;
}

img.cycling {
	background-color: #6200EA;
}

img.equestrian {
	background-color: #AA00FF;
}

img.kayak {
	background-color: #C51162;
}

img.kite_surfing {
	background-color: #f10000;
}

img.mountain_biking {
	background-color: #ff5e00;
}

img.orienteering {
	background-color: #ff9900;
}

img.paddling {
	background-color: rgb(223, 186, 0);
}

img.rowing {
	background-color: #64DD17;
}

img.running {
	background-color: #00C853;
}

img.skiing_cross_country {
	background-color: #098c00;
}

img.swimming {
	background-color: #00BFA5;
}

img.triathlon {
	background-color: #233e2d;
}

img.default {
	background-color: #7e7e7e;
}

@media only screen and (max-width: 1024px) {
	html {
		background: #fff;
	}

	input[type=submit].normal,
	button.normal {
		font-size: 12px;
		padding: 4px 10px;
	}

	.container {
		width: 100%;
	}

	input[type=email],
	input[type=text],
	input[type=password],
	textarea {
		height: 24px;
		padding: 3px 6px;
		font-size: 10px;
		line-height: 1.2;
	}
}

</style>
