const ROOT = 'live.tractrac.com'
const EM_ROOT = 'em.tractrac.com'

const constants = {
	ROOT: ROOT,
	ROOT_URL: `https://${ROOT}`,
	WEB_URL: `https://${ROOT}/web`,
	REST_API_URL: `https://${ROOT}/rest-api`,
	EM_ROOT_URL: `https://${EM_ROOT}/api/v2/website`,
	EM_ROOT_EVENTS_URL: `https://${EM_ROOT}/api/v2/events`
}

export default constants
